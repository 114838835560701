.close-checklist {
  .column-header {
    .content-section {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }

    .filter-buttons {
      margin-top: 0;
    }

    .meta-data-loading {
      display: flex;

      .checklist-review-loading-skeleton {
        flex: 1;
        margin-bottom: calc(var(--spacing-xl));

        &:first-child {
          margin-right: calc(var(--spacing-md));
        }

        &:last-child {
          margin-left: calc(var(--spacing-md));
        }
      }
    }

    .meta,
    .val {
      display: flex;
      flex-direction: column;
    }

    .meta {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      font-size: 10px;
      line-height: 1.4;
      color: var(--color-n500);
      font-weight: 400;
    }

    .val {
      font-size: var(--font-size-sm);
      line-height: 1.5;
      color: var(--color-n800);
      font-weight: 600;
    }

    .loan-number {
      font-size: 16px;
      font-weight: 700;
      color: var(--color-n800);
      margin-bottom: var(--spacing-md);
      line-height: 24px;
    }
  }
}
